import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import for React 18
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import App from './App';
import ChatComponent from './support/ChatComponent'; // Import your ChatComponent
import ChatVerifyImage from './support/ChatVerifyImage'; // Import your ChatComponent
import ChatVerifyText from './support/ChatVerifyText'; // Import your ChatComponent


import RenderingVerdict from './support/RenderingVerdict'; // Import your RenderingVerdict component
import FraudTrends from './support/FraudTrends'; // Import your FraudTrends component
import Reports from './support/Reports'; // Import your FraudTrends component
import ContactUs from './support/ContactUs';

import Header from './support/TopBar';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <div>
      {/* <Link className="navbar-brand" to="/">
        <img src="logo.png" alt="favicon" className="favicon" />
        VerifyPe
      </Link> */}
      <Header/>

      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/start-verification" element={<ChatComponent />} />
        <Route path="/verify-image" element={<ChatVerifyImage />} />
        <Route path="/verify-text" element={<ChatVerifyText />} />

        <Route path="/start-Reporting" element={<Reports />} />
        <Route path="/get-results" element={<RenderingVerdict />} />
        <Route path="/fraud-trends" element={<FraudTrends />} />
        <Route path="/contact-us" element={<ContactUs />} />

      </Routes>
    </div>
  </Router>
);