import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import './RenderingVerdict.css';

const getVerdictScoreLevel = (score) => {
  if (score < 30) return "low";
  if (score < 60) return "medium";
  if (score < 80) return "high";
  return "very-high";
};

const RenderingVerdict = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [verifypeVerdict, setVerifypeVerdict] = useState(null);
    const [score, setScore] = useState(0);
    const [markerPosition, setMarkerPosition] = useState("0%");

    const { verdict_data } = location.state || {};




    useEffect(() => {
        // Handle undefined or incorrect state



        if (verdict_data) {
            console.log("What is verifype_verdict_imt: ", verdict_data);
            if (verdict_data.score !== undefined) {
                setVerifypeVerdict(verdict_data);
                setScore(verdict_data.score);
                setMarkerPosition(`${verdict_data.score}%`);
            } else {
                console.error("Score is missing in verdict_data");
            }
        } else {
            console.error("verifype_verdict_imt is undefined or null");
        }

        window.scrollTo(0, 0); // Scroll to top on mount
    }, [location.state]);

    const handleBackClick = () => {
        navigate(-1);
        };

    if (!verifypeVerdict) {
        return <div>Loading or Error: Data not available</div>;
    }

    const capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .replace(/\b\w/g, char => char.toUpperCase());
    };

    return (
        <div>
            <img src="backbutton.svg" alt="Back" className="back-icon" onClick={handleBackClick} />

            <div className="verdict-container">
                <div className='pos-verdict'>
                        <h1>Verification Verdict</h1>
                        <p><strong>Verdict:</strong> {verifypeVerdict.verdict || "N/A"}</p>
                         <p><strong>Reason For Verdict :</strong> {verifypeVerdict.reasonForVerdict}</p>
                        <p><strong>Potential Cyber Crime:</strong> {verifypeVerdict.kind_of_cyber_crime}</p>


                        {/* Score Bar */}
                        <div className="verdict-bar">
                            <div className="verdict-bar-fill" data-score={getVerdictScoreLevel(score)}></div>
                            {/* Score Marker */}
                            <div
                                className="score-marker"
                                style={{ left: markerPosition }}
                                // data-score={score}
                            />
                        </div>
                        {verifypeVerdict.scoreComponents.length > 0 ? (
                            <>
                                <h2>Reasons:</h2>
                                <ul>
                                    {verifypeVerdict.scoreComponents.map((component, index) => (
                                        <li key={index} style={{ marginBottom: '10px' }}>
                                            <section style={{ marginBottom: '10px' }}>
                                                <strong>{capitalizeWords(component.scoreComponent)}:</strong>
                                            </section>
                                            <section>
                                                {component.description}
                                            </section>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <p></p>
                        )}

                </div>
                <div className='pos-verdict'>
                    {verifypeVerdict.proTips.length > 0 ? (
                            <>
                                <h2>Ways to Guard Against Fraud/Scam:</h2>
                                <ul>
                                    {verifypeVerdict.proTips.map((component, index) => (
                                        <li key={index} style={{ marginBottom: '10px' }}>
                                            <section style={{ marginBottom: '10px' }}>
                                                <strong>{capitalizeWords(component.tip)}:</strong>
                                            </section>
                                            <section>
                                                {component.description}
                                            </section>
                                        </li>
                                    ))}

                                    <li>
                                    <section style={{ marginBottom: '5px' ,marginTop:'10px'}}>
                                        <strong>Never Act in a Hurry</strong>
                                    </section>
                                    <section>
                                        Fraudsters create panic and impose time limits, making you act hastily.
                                    </section>
                                </li>
                                <li>
                                <section style={{ marginBottom: '5px' ,marginTop:'10px'}}>
                                        <strong>Talk to Your Friends and Family</strong>
                                    </section>
                                    <section>
                                        Try to connect with people you trust. Generally, your friends and family may have already experienced similar situations.
                                    </section>
                                </li>
                                </ul>
                              
                            </>
                        ) : (
                            <>
                            <h2>Ways to Guard Against Fraud/Scam:</h2>
                            <ul>
                                <li>
                                    <section style={{ marginBottom: '5px' ,marginTop:'10px'}}>
                                        <strong>Never Act in a Hurry</strong>
                                    </section>
                                    <section>
                                        Fraudsters create panic and impose time limits, making you act hastily.
                                    </section>
                                </li>
                                <li>
                                <section style={{ marginBottom: '5px' ,marginTop:'10px'}}>
                                        <strong>Talk to Your Friends and Family</strong>
                                    </section>
                                    <section>
                                        Try to connect with people you trust. Generally, your friends and family may have already experienced similar situations.
                                    </section>
                                </li>
                            </ul>
                            </>
                        )}

                        {verifypeVerdict.caseStudyLinks.length > 0 ? (
                            <>
                                <h2>Learn more about:</h2>
                                <ul>
                                {verifypeVerdict.caseStudyLinks.map((component, index) => (
          <li key={index} style={{ marginBottom: '10px' }}>
            <a 
              href={`https://www.google.com/search?q=${encodeURIComponent(component.title)}`} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              {component.title}
            </a>
          </li>
        ))}
                                </ul>
                            </>
                        ) : (
                            <p></p>
                        )}



                </div>
            </div>
        </div>
    );
};

export default RenderingVerdict;
