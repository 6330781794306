import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ChatComponent.css';
import { showLoading, hideLoading } from './Loading';

const VerifyImage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ image: null });

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const image = URL.createObjectURL(e.target.files[0]);
      setFormData(prevData => ({ ...prevData, image }));
    }
  };

  const handleVerifyNow = async () => {
    const data = new FormData();

    if (formData.image) {
      try {
        const response = await fetch(formData.image);
        const blob = await response.blob();
        const file = new File([blob], "image_to_verify.png", { type: "image/png" });
        data.append('image_to_verify', file);
      } catch (error) {
        console.error('Error converting image to blob:', error);
      }
    }

    try {
      showLoading();
      const response = await fetch(`${process.env.REACT_APP_VERIFYPE_BACKEND}/fraud-detection`, {
        method: 'POST',
        body: data,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const response_data = await response.json();
      if (response_data.server_status !== 200) {
        alert(response_data.message);
        setFormData(prevData => ({ ...prevData, image: null }));
        return;
      } else {
        navigate('/get-results', { state: { verdict_data: response_data.analysis } });
      }
    } catch (error) {
      console.error('Error verifying:', error);
      alert("We could not process your request currently. Please try again later.");
    } finally {
      hideLoading();
    }
  };

  const handleBackButtonClick = () => {
    navigate('/');
  };

  return (
    
    <div className="containerChatVerify">
      <img src="backbutton.svg" alt="Back" className="back-icon" onClick={handleBackButtonClick} />
      <div className="inputBox">
        {!formData.image && (
          <input type="file" accept="image/*" onChange={handleFileChange} />
        )}
        {formData.image && (
          <div>
            <img src={formData.image} alt="Selected" className="imagePreview" />
          </div>
        )}
      </div>
      <button className="cta-button" onClick={handleVerifyNow}>Verify Now</button>
    </div>
  );
};

export default VerifyImage;
